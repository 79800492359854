<template>
  <div>
    <div class="title">
      <van-icon name="arrow-left" class="lefticon" @click="goBack()" />
      <p>支付</p>
    </div>
    <div class="detail">
      <!-- <form action="https://www.lianwen.com/m/mobile/pay_phone" method="POST"> -->
      <!-- <form method="GET" action ref="Form"> -->
      <!-- <form> -->
      <ul class="yeadprice">
        <li class="yeadli1">
          <div>论文题目：{{msgs.title}}</div>
        </li>
        <li class="yeadli2">
          <div>作者：{{msgs.writer}}</div>
        </li>
        <li class="yeadli2">
          <div>字数：{{msgs.words}}</div>
        </li>
        <li class="yeadli2">
          <div>
            总价：
            <span style="font-size:0.48rem;color:#FF7F50">{{msgs.price}}元</span>
          </div>
        </li>
      </ul>
      <ul class="yeadpay" v-if="Android">
        <div
          style="background:#9ACD32;color:#fff;text-align:center;width:100%;"
          @click="topayPage"
        >已完成支付</div>
      </ul>
      <ul class="yeadpay" v-else>
        <span class="zfprice">总价：￥{{msgs.price}}</span>
        <button class="zfbtn" @click="toPay">
          <span
            style="width: 1.9rem;
                     height: 100%;
                     color: #fff;
                     line-height: 30px;
                     font-size:0.39rem"
          >支付</span>
        </button>
      </ul>
      <!-- </form> -->
      <van-action-sheet
        v-model="show"
        :actions="actions"
        cancel-text="取消"
        @cancel="onCancel"
        @select="onselect"
      />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      orderid: "",
      msgs: [],
      iswx: "",
      weixinCode: "",
      show: false,
      actions: [{ name: "支付宝" }, { name: "微信" }],
      acctive: "",
      aciondz: "",
      Android: false
    };
  },
  created() {
    this.msgs = this.$route.query;
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      console.log(vm._data);
      if (!from.name) {
        vm._data.Android = true;
      }
    });
  },
  mounted() {},
  watch: {},
  methods: {
    goBack() {
      this.$router.back();
    },
    topayPage() {
      this.$router.push("/report");
    },
    onCancel() {},
    toPay() {
      this.show = true;
    },
    onselect(e) {
      console.log(e, 312);
      switch (e.name) {
        case "支付宝":
          this.show = false;
          window.location.href = `https://www.lianwen.com/payments/${this.msgs.id}/alipay_wap`;
          break;
        case "微信":
          window.location.href = `https://www.lianwen.com/payments/${this.msgs.id}/wechat_wap`;
          this.show = false;
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
.title {
  display: flex;
  background: #228ef7;
}
.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.4rem;
}
.title p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}
.detail {
  margin-top: 0.3rem;
  background-color: #fff;
}
.detail-ul {
  background: #fff;
  margin-bottom: 0.2rem;
  border-bottom: 0.02rem solid #f0f1f5;
}
.detail-ul li {
  line-height: 0.5rem;
  padding: 0.3rem 0.3rem;
  text-indent: 1.5em;
  color: #333333;
}
.yead {
  margin-top: 0.57rem;
  background: #fff;
  padding: 0.5em 0px;
  white-space: normal;
  border-style: solid none;
  border-top-width: 1px;
  border-top-color: #228ef7;
  display: block;
  font-size: 1em;
  font-family: inherit;
  font-style: normal;
  font-weight: inherit;
  text-decoration: inherit;
  color: rgb(166, 166, 166);
  border-bottom-width: 1px;
  border-bottom-color: #228ef7;
  box-sizing: border-box;
  border-bottom: 0.25rem solid #fff;
}
.yead section {
  margin-top: -1.2em;
  text-align: center;
  padding: 0;
  border: none;
  line-height: 1.4;
  box-sizing: border-box;
}
.yead1 {
  border-color: #228ef7;
  color: rgb(255, 255, 255);
  font-family: inherit;
  font-size: 1em;
  font-style: normal;
  font-weight: inherit;
  padding: 8px 23px;
  text-align: center;
  text-decoration: inherit;
  background-color: #228ef7;
}
.yead3 {
  padding: 0.3rem;
  padding-bottom: 0;
  color: #202020;
}
.yead3 p {
  margin: 0.6rem 0;
  white-space: normal;
  text-align: left;
  font-size: 1em;
  text-indent: 1.5em;
}
.yeadprice {
  border-bottom: 0.3rem solid #fff;
  padding: 0.3rem 0.3rem;
}
.yeadli1 {
  display: flex;
  justify-content: space-between;
}
.yeadli2 {
  margin-top: 0.3rem;
}
.yeadpaystyle {
  border-bottom: 0.3rem solid #f0f1f5;
}
.yeadpay {
  height: 0.8rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 0.8rem;
  background: #ddd;
  display: flex;
  justify-content: space-between;
}
.yeadpay li {
  background: #228ef7;
  color: #fff;
}
.zfbstyle input {
  vertical-align: middle;
  height: 60px;
}
.zfbtn {
  background: #228ef7;
  border: none;
  width: 1.9rem;
  height: 100%;
  color: #fff;
  line-height: 30px;
}
.zfprice {
  margin-left: 0.7rem;
  color: #228ef7;
  font-size: 0.37rem;
  align-self: center;
}
</style>